<template>
  <v-dialog v-model="show" max-width="550px" max-height="551px" persistent>
    <v-card class="px-0 pb-0 pt-0">
      <v-card-title class="px-4">
        <span class="text-h5" :style="'color: #182026;font-weight:500;font-size:20px !important'">{{ titulo }}</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon class="ma-0">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-0 pa-4">
        <v-form ref="form" v-model="valid" lazy-validation class="pa-0 ma-0">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                id="idCodigo"
                v-model="incidence.code"
                label="Código *"
                :rules="[rules.requiredCode, rules.digit, rules.exist]"
                required
                v-mask="'##'"
                maxlength="2"
                minlength="2"
                persistent-hint
                :hint="codigoHint"
                dense
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-4 pb-0">
              <v-text-field
                id="idNome"
                v-model="incidence.name"
                :rules="[rules.requiredName]"
                hide-details="auto"
                label="Nome *"
                dense
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-4 pb-0">
              <v-list-item class="pb-4 pl-0 pr-0">
                <v-combobox hide-details="auto" v-model="incidence.group" :items="groups" label="Grupo" outlined dense>
                  <template slot="item" slot-scope="data">
                    <v-tooltip bottom v-if="data.item.length > 62" max-width="250" max-height="56">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ start_and_end(data.item, 62) }}
                        </span>
                      </template>
                      <span>{{ data.item }}</span>
                    </v-tooltip>
                    <span v-else> {{ start_and_end(data.item, 62) }}</span>
                  </template>
                </v-combobox>
              </v-list-item>
            </v-col>
          </v-row>
        </v-form>
        <div class="text-left pt-8" style="font-size: 14px; color: #575767; font-weight: 400">
          * Campos de preenchimento obrigatório
        </div>
        <div class="text-red pl-2" style="color: #610006" v-if="invalidForm">
          Preencher todos os campos obrigatórios
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close" id="btnCancel" class="btn-ag-tertiary"> Cancelar</v-btn>
        <v-btn @click="save()" class="btn-ag-primary" id="btnConfirmOredit" :disabled="isEnableSave">
          {{ labelBotao }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import eSocialService from '@/services/eSocialService.js';
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '../../../../../main.js';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'ModalIncidenceINSSNew',
  props: ['incidences'],

  data() {
    return {
      valid: true,
      invalidForm: false,
      codigoHint: 'Preencha até dois números (0-9)',
      groups: [
        'Sem grupo',
        'Base de cálculo das contribuições sociais - Salário de contribuição',
        'Contribuição descontada do segurado sobre salário de contribuição',
        'Outros',
        'Suspensão de incidência sobre salário de contribuição em decorrência de decisão judicial',
      ],
      incidence: {},
      existentCode: true,
      titulo: 'Adicionar incidência',
      labelBotao: 'Salvar',
      show: false,
      persistentCodigo: true,
      rules: {
        requiredCode: (code) => !!code || 'Preencha até dois números (0-9)',
        requiredName: (name) => !!name || 'Preencha um nome',
        digit: (code) => /^\d{2}$/.test(code) || 'Preencha até 2 números (0-9)',
        exist: (code) => !this.codeExist(code) || 'Este código já existe! Preencha outro código',
      },
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
    isEnableSave() {
      let count = 0;
      if (![undefined, null].includes(this.incidence.code)) {
        if (!this.isEmpty(this.incidence.code)) {
          ++count;
        }
      }
      if (![undefined, null].includes(this.incidence.name)) {
        if (!this.isEmpty(this.incidence.name)) {
          ++count;
        }
      }
      if (this.existentCode) {
        --count;
      }

      if (count === 2) {
        return false;
      } else {
        return true;
      }
    },
  },
  validations: {
    incidence: {
      code: { required }, // Matches this.firstName
      name: { required }, // Matches this.lastName
    },
  },

  created() {
    this.init();
  },

  methods: {
    isEmpty(str) {
      if (str.trim().length === 0) {
        return true;
      } else {
        return false;
      }
    },
    async save() {
      this.$v.incidence.$touch();

      if (this.$v.incidence.$error || this.$v.incidence.$pending) {
        this.$v.$reset();
        this.invalidForm = true;
        return;
      }
   
      if (this.incidence.group === 'Sem grupo' || this.incidence.group === undefined) {
        this.incidence.group = '';
      }
      let incidenceLocal = {
        code: this.incidence.code,
        name: this.incidence.name,
        group: this.incidence.group,
        user: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
      };
      const response = await eSocialService.rubricAutomationConfig.incidenceINSSSave(incidenceLocal);
      if (response) {
        (this.incidence.code = ''), (this.incidence.name = ''), (this.incidence.group = ''), this.$emit('refresh');
        this.close();
        this.showToast('Incidência adicionada com sucesso!', 'success', 78);
      } else {
        this.showToast('Erro ao adicionar incidencia', 'warning', 78);
      }
    },
    codeExist(code) {
      if (this.incidences.some((item) => item.code === code)) {
        this.existentCode = true;
        return true;
      } else {
        this.existentCode = false;
        return false;
      }
    },
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },

    async open() {
      this.incidence = {};
      this.titulo = 'Adicionar incidência';
      this.labelBotao = 'Salvar';
      this.show = true;
    },

    close() {
      this.incidence = {};
      this.invalidForm = false;
      this.$refs.form.resetValidation();
      this.show = false;
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
    start_and_end(str, lengthStr) {
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },
  },
};
</script>
<style scoped lang="scss">
@import './src/design/components/input.scss';
@import './src/design/components/select.scss';
@import './src/design/components/datepicker.scss';

.v-card__actions {
  padding: 16px !important;
}
::v-deep .v-text-field__details .v-messages {
  text-align: right !important;
}
::v-deep .v-text-field__details .v-messages__message {
  background: none !important;
}
::v-deep .v-text-field__details .v-messages.theme--light.error--text {
  text-align: left !important;
  background: url('https://api.iconify.design/bxs/error-circle.svg?color=rgba(150, 45, 51, 1)') no-repeat top left !important;
}
</style>
